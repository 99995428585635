html{
  font-size: 62.5%;
}


body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444;
  background: #421968;
  background-image: url('./assets/images/background.jpg');
  
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


