/* ContactForm.css */

.form-container {
    max-width: 500px;
    margin: 2rem auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
  }
  
  .form-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .form-container input[type="text"],
  .form-container input[type="email"],
  .form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .form-container textarea {
    height: 150px;
  }
  
  .form-container input[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form-container input[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .message-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .message {
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  /* Animation */
  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .form-container {
    animation: slideIn 0.5s ease forwards;
  }
  