

  ////////////
  /* About.scss */

.about-container {
  flex-direction: row;
  font-family: 'Brush Script MT', cursive;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the container takes up the full height of the viewport */
  margin: auto;
}


.skill-project-container{
  float: left;
}

/* Media query for smartphone view */
@media only screen and (max-width: 768px) {
  .about-container {
      width: 100%; /* Make sidebar full width */
      height: auto; /* Allow height to adjust based on content */
      position: static; /* Remove fixed positioning */
      overflow-y: hidden; /* Hide vertical scrollbar */
  }}




