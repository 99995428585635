.container {
    text-align: center;
    font-family: 'Brush Script MT', cursive;
    padding-left: 80px;

    @media screen and (max-width: 600px) {
      padding-left: 60px;
    }
  }
  
  .name {
    font-size: 24px;
    margin-top: 50px;
    color: aliceblue;
  }

  .animationContainer{
    height: 200px;
    width: auto;
    z-index: 1;
  }
  
  .prashast-image {
    width: 200px; /* Adjust the width of the image */
    height: auto;
    vertical-align: middle; /* Align the image vertically */
    animation: vibrate 0.1s infinite, swipeRight 2s infinite;
  }
  
  .name span {
    display: inline-block;
    margin-left: 10px; /* Adjust the spacing between the image and text */
  }
  
  .info {
    font-size: 18px;
    margin-bottom: 50px;
    color: aliceblue;
  }
  
  .animations {
    display: flex;
    justify-content: center;
  }
  
  .animation {
    width: 105px; /* Make it responsive */
    height: 105px; /* Maintain aspect ratio */
    margin: 10px;
    background-color: #ebedf0;
    border-radius: 50%;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  display: flex; /* Allow content to be centered */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  border: 0px solid hsl(60, 7%, 97%); /* Optional: Add border for better visualization */
  font-size: 2em; /* Increase font size */
  }
  
  .animation1 {
    
    animation-name: colorChange;
    cursor: pointer;
  }
  
  .animation2 {
    animation-name: pulse;
    cursor: pointer;
  }
  
  .animation3 {
    animation-name: colorChange;
    cursor: pointer;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes colorChange {
    0% {
      background-color: #f7fac7;
    }
    50% {
      background-color: #f4f7f5;
    }
    100% {
      background-color: #f7fac7;
    }
  }
  
  @keyframes vibrate {
    0% {
      transform: translateX(-2px);
    }
    50% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(-2px);
    }
  }
  
  @keyframes swipeRight {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(100%); /* Swipe to the right */
    }
  }

  