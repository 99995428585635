.project {
  text-align: center;
  h3{
    font-size: 3rem;
    color: aliceblue;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow images to wrap to the next line on smaller screens */
}

.projectImage {
  position: relative;
  margin: 20px;
  flex: 1 1 50%; /* Each image takes 50% of the container width on small screens */
}

.projectImage img {
  transition: filter 0.3s ease;
  width: 80%; /* Set image width to 100% of the container */
  max-width: 310px; /* Limit maximum image width */
  aspect-ratio: 1; /* Maintain aspect ratio */
  border-radius: 10px;
  
}

.projectImage p {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  
  color: rgb(0, 0, 0);
  margin: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.projectImage:hover img {
  filter: blur(5px); 
}

.projectImage:hover p {
  opacity: 1; 
}


.projectImage :after, .projectImage:before{
  mix-blend-mode:multiply;
  filter:none;
  z-index: -1;
  content:'';
  width:calc(100% + (50px * 2));
  height:calc(100% + (50px * 2));
  position: absolute;
  display: block;
  animation: border 10s ease-in-out infinite;
  transform:translateX(-50px) translateY(-50px);
}
@keyframes border {
  0%, 100% {
    -webkit-clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
            clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
  }
  50% {
    -webkit-clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
            clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
  }
}

.projectImage :after{
  animation-delay: -5s;
  background-color: #f6f6f6;
  clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
}
.projectImage :before {
  background-color: #AA4465;
    clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
}

.projectImage :hover:after{
  animation-delay: -0.1s;
}
.projectImage :hover:before, .projectImage :hover:after {

          animation-duration: 0.2s;
}

/*
.project {
    text-align: center;
    float: left;

    h3{
        font-size: 20px;
        color: aliceblue;
    }
  }
  
  .imageContainer {
    display: flex;
    justify-content: center;
    float: left;
  }
  
  .projectImage {
    position: relative;
    margin: 20px;
  }
  
  .projectImage img {
    transition: filter 0.3s ease; 
    width: 310px;
    height: 240px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #ffffff;
  }
  
  .projectImage p {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    
    margin: 0;
    opacity: 0;
    transition: opacity 0.3s ease; 
  }
  
  .projectImage:hover img {
    filter: blur(5px); 
  }
  
  .projectImage:hover p {
    opacity: 1; 
  }
  */