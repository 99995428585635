.education-tab {
    font-family: 'Brush Script MT', cursive;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    z-index: 1000;

    margin: auto; /* Center horizontally */
    position: absolute; /* Position absolutely */
    top: 45%; /* Move to the vertical center */
    left: 39%;
    
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #999;
    transition: color 0.3s ease;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  .content table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .content th,
  .content td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .content th {
    font-weight: bold;
    background-color: #f2f2f2;
  }
  
  .content tr:last-child td {
    border-bottom: none;
  }
  