.description {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    z-index: 10000;
    position: absolute; /* Position absolutely */
    top: 0%; /* Move to the vertical center */
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 60%;
    min-height: 85vh;
    padding: 3rem;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #999;
    transition: color 0.3s ease;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  .content h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .content p {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .content a {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    font-size: 15px;
  }
  
  .content a:hover {
    text-decoration: underline;
  }
  