.skill{
  margin-top: 50px;
  text-align: center;
  float: left;
  margin-left: 7rem;
}

.category {
  font-size: 24px;
  margin-bottom: 20px;
  color: aliceblue;
}

.skills-container {
  perspective: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.logoImg {
  cursor: pointer;
  width: 100px;
  height: 100px;
  margin: 10px;
  animation: rotate 20s linear infinite;
  mix-blend-mode: color-burn;
  object-fit: contain;
}

/* Define animation keyframes */
@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

/* Pause animation on hover */
.logoImg:hover {
  animation-play-state: paused;
}

/* Resume animation when not hovered */
.logoImg:not(:hover) {
  animation-play-state: running;
}

/*.skill-category {
  margin-top: 50px;
  text-align: center;
}

.category {
  font-size: 24px;
  
  margin-bottom: 20px;
  color: aliceblue;
}

div img{
  width: 100px;
  height: 100px;
}

.skills-container {
  perspective: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.logoImg{
  cursor: pointer;
  width: 100px;
  height: 100px;
  margin: 10px;
  animation: rotate 20s linear infinite;
  mix-blend-mode: color-burn;
  object-fit: contain;
}



@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.logoImg:hover {
  animation-play-state: paused; 
  
}

.logoImg:not(:hover) {
  animation-play-state: running; 
}
*/