// MoreTab.scss

.more-tab {
    font-family: 'Brush Script MT', cursive;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    z-index: 1000;
    max-height: calc(100vh - 100px); /* Adjust height as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    transform: translate(0%, -50%);
  
    
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #999;
    transition: color 0.3s ease;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  .content {
    padding-top: 20px;
  }
  
  .experience {
    margin-bottom: 30px;
  }
  
  .experience h3 {
    margin-bottom: 10px;
  }
  
  .experience p {
    margin-bottom: 5px;
  }
  
  .experience ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  